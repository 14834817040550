import React from "react";
import ProblemImg from "../assets/img/home/problem-img.svg";
import SolutionImg from "../assets/img/home/solution-img.svg";
import ProblemPeopleImg from "../assets/img/home/problem-people-img.svg";
import SolutionPeopleImg from "../assets/img/home/solution-people-img.svg";
import Check from "../assets/img/home/check.svg";
import DecentralizedImg from "../assets/img/home/decentralized.svg";
import Automated from "../assets/img/home/automated.svg";
import Transparent from "../assets/img/home/transparent.svg";
import Utility from "../assets/img/home/utility.svg";
import shape from "../assets/img/home/shaped2.svg";
import shapemobile from "../assets/img/home/shaped2-mobile.svg";

const Decentralized = () => {

    return (
        <>
        <div className="decentralized-container">
            <h1 className="header-text ">
                Decentralized, automated, on-chain rewards
            </h1>
            <p className="description">
                <span className="white">
                    Native web3 rewards infrastructure built for scale and real utility creation.
                </span> {" "}
            </p>
            <div className="columns">
                <div className="column">
                    <img src={ProblemImg} alt="problem-img" className="problem-img" />
                    <div className="column-txt-img">
                    <p className="column-text">
                        Web3 infrastructure can only (largely manually) reward a small number of people for a small number of contributions.
                        <br /><br />
                        Creating real utility in web3 depends on scaling many valuable contributions from many valued contributors.
                    </p>
                    <img src={ProblemPeopleImg} alt="problem-img" className="problem-people-img" />
                    </div>
                </div>
                <div className="column">
                    <img src={SolutionImg} alt="problem-img" className="solution-img" />
                    <div className="column-txt-img">
                    <p className="column-text">
                        ThriveCoin rewards and recognizes valuable contributions to web3 communities.
                        <br /><br />
                        <ul className="column-list">
                            <li> <img src={Check} alt="check-img" />It’s automated. </li>
                            <li><img src={Check} alt="check-img" /> It’s bot-protected. </li>
                            <li><img src={Check} alt="check-img" />It’s scalable. </li>
                        </ul>
                    </p>
                    <img src={SolutionPeopleImg} alt="problem-img" className="solution-people-img"/>
                    </div>
                </div>
            </div>

            <div className="how-we-do-it-wrapper">
                <h1 className="header-text">
                    How we do it
                </h1>

                <div className="columns-wrapper">
                    <div className="column">
                        <img src={DecentralizedImg} />
                        <div className="column-text-wrapper">
                            <h2>Decentralized</h2>
                            <p>Most web3 communities reward few people for few contributions. <b>That’s centralized. </b>
                                <br /><br />
                                ThriveCoin rewards many people for many contributions. <b>That’s decentralized!</b></p>
                        </div>
                    </div>
                    <div className="column">
                        <img src={Automated} />
                        <div className="column-text-wrapper">
                            <h2>Automated</h2>
                            <p>Most web3 communities only manually reward contributions. <b>That doesn’t scale.</b>
                                <br /><br />
                                ThriveCoin autovalidates and auto-rewards unlimited contributions. <b>That scales!</b></p>
                        </div>
                    </div>
                    <div className="column">
                        <img src={Transparent} />
                        <div className="column-text-wrapper">
                            <h2>Transparent</h2>
                            <p>Most contributions to web3 communities aren’t on-chain. <b>That’s not transparent. </b>


                                <br /><br />
                                ThriveCoin records all contributions on-chain. <b>That’s transparent!</b> </p>
                        </div>
                    </div>
                    <div className="column">
                        <img src={Utility} />
                        <div className="column-text-wrapper">
                            <h2>Value-creating</h2>
                            <p>Most web3 communities struggle to provide  value to members.  <b>They lack utility. </b>


                                <br /><br />
                                ThriveCoin incentivizes all community members to create value. <b>That’s utility! </b></p>
                        </div>
                    </div>

                </div>
              
            </div>
         
        </div>
           <div className="bottom-shape">
           <img src={shape}  className="shaped2"/>
           <img src={shapemobile} className="shaped2mobile" />
         </div>
         </>
    );
}

export default Decentralized;