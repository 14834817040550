import React from "react";
import LogoFooter from "../assets/img/logo_2.svg";
import {ReactComponent as Discord} from "../assets/icons/discord_fill.svg";
import {ReactComponent as Facebook} from "../assets/icons/facebook_fill.svg";
import {ReactComponent as Twitter} from "../assets/icons/twitter_fill.svg";
import {ReactComponent as Linkedin} from "../assets/icons/linkedin_fill.svg";
import {ReactComponent as Instagram} from "../assets/icons/instagram_fill.svg";
import ScrollToSection from "./ScrollToSection";

const currentYear = new Date().getFullYear();

const Footer = () => {
  const scrollToTop = () => {
    const banner = document.querySelector(".header");
    banner.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div id="footer" className="container">
      <div className="footer">
       {/*<ScrollToSection scrollToSection={scrollToTop}/>*/}
     
        <img src={LogoFooter} alt="Logo" />
        <div className="footer-right">
        <p className="primary">
          &copy; {currentYear} ThriveCoin All rights reserved.
        </p>

        {/*<div className="footer-social-media">
          <a target="_blank" href="https://discord.gg/fBJhhYBNq3">
            <Discord className="footer-social-media-icon" />
           </a>
          <a target="_blank" href="https://www.facebook.com/thrivecoinhq">
            <Facebook className="footer-social-media-icon" />
           </a>

           <a target="_blank" href="https://twitter.com/ThriveCoinHQ">
            <Twitter className="footer-social-media-icon" />
           </a>

           <a target="_blank" href="https://www.linkedin.com/company/thrivecoin/">
            <Linkedin className="footer-social-media-icon" />
           </a>

           <a target="_blank" href="https://www.instagram.com/thrivecoin">
            <Instagram className="footer-social-media-icon" />
           </a>
        </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Footer;
