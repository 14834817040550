import React, { useContext, useState } from "react";
import Modal from "react-modal";
import Logo from "../assets/img/logo_green.svg";
import {ReactComponent as CloseIcon} from "../assets/icons/close_icon.svg";
import submitToGoogleSheets from "./submitToGoogleSheets";
import { Input, Select, TextArea } from "@thrivecoin/ui-components";
import { prospectiveOptions } from "../utils/FormOptions";
import countries, { isValid } from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import styled from "styled-components";
import { useRole } from "./SignUpForm";
import ToastContext from "./ToastService/ToastContext";
countries.registerLocale(enLocale);

const countryNames = countries.getNames("en", { select: "official" });
const firstCountry = countryNames.US;
const FullWidthSelect = styled(Select)`
  width: 100%;
`;

const FullWidthTextArea = styled(TextArea)`
  width: 100%;
`;

const ModalSignUp = ({ isOpen, close, role, role2 }) => {
  const [values, setValues] = useState({
    isProspective: role,
    description: '',
    fullName: '',
    email: '',
    country: firstCountry,
    orgName: '',
    orgWeb: '',
    otherDescription: '',
  });

  const [valid, setValid] = useState({valid: false});
  
  const handleIsProspectiveInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      isProspective: event.target.value,
    }));
  };

  const handleDescriptionInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      description: event.target.value,
    }));
  };

  const handleFirstNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      fullName: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleCountryInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      country: event.target.value,
    }));
  };
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setValid({...valid, valid: true});

    if(!values.description) {
      setValid({...valid, valid: false});
    }
    if(!values.fullName) {
      setValid({...valid, valid: false});
    }
    if(!values.email) {
      setValid({...valid, valid: false});
    }
    
    setSubmitted(true);
    
    if(valid.valid === true){
      submitToGoogleSheets(event).then((data) => {
        successToast({ content: "Thank you for reaching out! We’ll get back to you soon." });
        close();
      });
    }
  }
  
  
  const { successToast } = useContext(ToastContext);
  const { onRoleChange, showOther } = useRole(role);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      contentLabel="My dialog"
      className="modal-wrap"
      overlayClassName="overlay"
      closeTimeoutMS={500}
    >
      <div className="modal-content bg-white">
        <div className="modal-content-form">
          <img className="logo" src={Logo} alt="Logo" />

          <CloseIcon className="close-icon" onClick={close}/>

          <form className="modal-content-form-inputs" onSubmit={handleSubmit}>
            <h3 className="bold mt50">Sign Up.</h3>

            <div className="modal-content-form-inputs-inner mt30">
              <div className="label-wrapper mb10 items-center ">
                <div className="label-singup">
                  <label>I am a prospective*</label>
                </div>
                <div className="items-column w-100">
                  <FullWidthSelect name="role" onChange={onRoleChange, handleIsProspectiveInputChange} defaultValue={role}>
                    {prospectiveOptions.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </FullWidthSelect>
                  {submitted && !values.isProspective && <span className="error-message">This is a required field.</span>}
                </div>
              </div>

              {showOther && (
                <div className="label-wrapper input-wrap mb10">
                  <div className="label-singup">
                    <label></label>
                  </div>
                  <Input type="text" name="role2" required placeholder="Please specify" defaultValue={role2} />
                </div>
              )}

              <div className="label-wrapper input-wrap mb30">
                <div className="label-singup">
                  <label>What interests me about ThriveCoin*</label>
                </div>

                <div className="items-column w-100">
                  <FullWidthTextArea
                    name="interest"
                    placeholder="Describe your motivation to sign up..."
                    onChange={handleDescriptionInputChange}
                  ></FullWidthTextArea>
                  {submitted && !values.description && <span className="error-message">This is a required field.</span>}
                </div> 
              </div>

              <div className="label-wrapper input-wrap mb10">
                <div className="label-singup">
                  <label>Name*</label>
                </div>
                <div className="items-column w-100">
                  <Input type="text" name="fullName" placeholder="First and Last Name" 
                       value={values.fullName}
                       onChange={handleFirstNameInputChange}/>
                       {submitted && !values.fullName && <span className="error-message">This is a required field.</span>}
                </div>

              </div>

              <div className="label-wrapper input-wrap mb10">
                <div className="label-singup">
                  <label>Email*</label>
                </div>
                <div className="items-column w-100">
                  <Input type="email" name="email" placeholder="Email address"  onChange={handleEmailInputChange}/>
                  {submitted && !values.email && <span className="error-message">This is a required field.</span>}
                </div>
              </div>

              <div className="label-wrapper mb10 items-center ">
                <div className="label-singup">
                  <label>Country of Residence*</label>
                </div>
                <div className="items-column w-100">
                  <FullWidthSelect name="country" onChange={handleCountryInputChange}>
                    {Object.keys(countryNames).map((key) => (
                      <option value={countryNames[key]} selected={countryNames[key] === firstCountry}>{countryNames[key]}</option>
                    ))}
                  </FullWidthSelect>
                  {submitted && !values.country && <span className="error-message">This is a required field.</span>}
                </div>
              </div>

              <div className="label-wrapper input-wrap mb10">
                <div className="label-singup">
                  <label>Organization Name</label>
                </div>
                <Input type="text" name="optionName" placeholder="My Company Inc." />
              </div>

              <div className="label-wrapper input-wrap mb10">
                <div className="label-singup">
                  <label>Organization Website</label>
                </div>
                <Input type="text" name="website" placeholder="https://www.mycompany.com" />
              </div>

              <div className="label-wrapper input-wrap mb50">
                <div className="label-singup">
                  <label>Other Thoughts</label>
                </div>
                <FullWidthTextArea
                  name="thoughts"
                  placeholder="Tell us more about why you’re getting in touch..."
                ></FullWidthTextArea>
              </div>

              <div className="modal-content-buton">
                <span>*Required</span>
                <button  type="submit" className="btn btn--green">Sign me up</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ModalSignUp;
