import React from "react";
import "./App.css";
import "./assets/sass/main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import GetStarted from "./components/GetStarted/GetStarted";
import OurTeam from "./components/OurTeam/OurTeam";
import Connect from "./components/Connect/Connect";
import Layout from "./Layout";
import useUserSnap from "./userSnap";
import useTracking from "./components/hooks/useTracking";
import useTrackExternalLink from "./components/hooks/useTrackExternalLink";
import Articles from './components/Articles/Articles';
import Post from './components/Articles/Post/Post';
import WhatWeCreate from './components/WhatWeCreate/WhatWeCreate';
import PersonalLegend from "./components/PersonalLegend/PersonalLegend";
import AboutUs from './components/AboutUs/AboutUs';

function App() {
  useUserSnap();
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

const AppRoutes = () => {
  useTracking();
  useTrackExternalLink();

  return (
    <Routes>
      <Route default element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        {/*<Route path="/getstarted" element={<GetStarted />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/connect" element={<Connect />} />*/}
        {/*<Route path="/articles" element={<Articles />} />
        <Route path="/personallegend" element={<PersonalLegend />} />
        <Route path="/articles/:id" element={<Post   />} />*/}
      </Route>
    </Routes>
  );
};

export default App;
