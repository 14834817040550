import React from "react";
import Email from "../assets/img/home/email.svg";
import Twitter from "../assets/img/home/twitter.svg";
import BgStars from "../assets/img/home/bg-stars.svg";

const JoinOurCommunities = () => {
  return (
    <div className="container items-column join-our-communities-section">
    
      <img src={BgStars} alt="BgStars" className="bg-stars" />

      <div className="join-our-communities">
        <div className="join-our-communities-content">
          <h1 className="header-text">Stay Connected</h1>
        </div>

        <div className="join-box-wrapper">

          <a className="box box--blue" href="https://twitter.com/thrivecoinhq" target="_blank" rel="noreferrer">
            <img src={Twitter} alt="Twitter" className="twitter-icon" />
            <h6>Follow us on Twitter</h6>
          </a>

          <a className="box box--green" href="mailto: support@thrivecoin.com" >
            <img src={Email} alt="Partner" className="msg-icon" />
            <h6>Write us an Email</h6>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinOurCommunities;
