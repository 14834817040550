import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Banner from "./Banner";
import Team from "./Team";
import CompanyValues from "./CompanyValues";
import Jobs from "./Jobs";
import Signup from "../Signup";
import Footer from "../Footer";

class MySection extends React.Component {
  render() {
    return <div className={'section ' + this.props.className} >{this.props.content}</div>;
  }
}

const anchors = [
  "BannerOurTeam",
  "Team",
  "CompanyValues",
  "Jobs",
  "Signup",
  "Footer"
];

const GetStarted = () => {
  return (
    <ReactFullpage
      autoScrolling={false}
      anchors={anchors}
      css3={true}
      normalScrollElements = '.ReactModal__Body--open, #footer'
      responsiveWidth= {1199}
      navigation
      navigationTooltips={anchors}
      sectionsColor={[
        "#88CFDE",
        "",
        "",
        "",
        "",
        "#FFFFFF"
      ]}
      onLeave={(origin, destination, direction) => {
        //console.log("onLeave event", { origin, destination, direction });
      }}
      render={({ state, fullpageApi }) => {
        //console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

        return (
          <div>
            <MySection content={<Banner fullpageApi={fullpageApi}/>} />
            <MySection className="gr-navy hidden-r" content={<Team/>} />
            <MySection className="gr-white-blue" content={<CompanyValues/>} />
            <MySection className="gr-navy" content={<Jobs/>} />
            <MySection className="gr-green" content={<Signup description='
                If you want to be a founding community member - or found one of our first communities - we want to know you.' />} />
            <MySection className="fp-auto-height" content={<Footer/>} />
          </div>
        );
      }}
    />
  );
};

export default GetStarted;
