import React from "react";
import BannerHeader from "./BannerHeader";
import Footer from "./Footer";
import Decentralized from "./Decentralized";
import OurCommunity from "./OurCommunity";
import Testimonail from "./Testimonial";
import OurRoadMap from "./OurRoadMap";
import TheMissinoaries from "./The-Missionaries";
import JoinOurCommunities from "./JoinOurCommunities";

const Home = () => {
  const scrollToBottom = () => {
    const banner = document.querySelector(".introduction");
    banner.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <div className="bg-blue-lighter banner-wrapper">
        <BannerHeader />
        <div className="next-section center bg-gradient-blue"></div>
        {/*<ScrollToSection
          className="arrow-navigatior-top"
          scrollToSection={scrollToBottom}
        />*/}
      </div>
      <div className="decentralized-wrapper">
        <Decentralized />
      </div>
      <div className="our-community-wrapper">
        <OurCommunity />
      </div>

      <div className="testimonial-wrapper">
        <Testimonail />
      </div>

      <div className="our-road-map-wrapper">
        <OurRoadMap />
      </div>

      <div className="thc-missionaries-wrapper">
        <TheMissinoaries />
      </div>
      <div className="gr-navy join-our-communities-wrapper">
        <JoinOurCommunities />
      </div>
      <div className="fp-auto-height footer-wrapper">
        <Footer />
      </div>
    </div>
  );
};

export default Home;
