import React from "react";
import JoeImg from "../assets/img/home/joe.svg";
import Cloud1 from "../assets/img/home/cloud1.svg";
import Cloud2 from "../assets/img/home/cloud2.svg";
import TestimonialBox from "../assets/img/home/testimonial-box.svg";
import TestimonialBoxTablet from "../assets/img/home/testimonial-box-tablet.svg";
import TestimonialBoxMobile from "../assets/img/home/testimonial-box-mobile.svg";

const Testimonial = () => {

    return (
        <div className="testimonial-container">

            <img src={Cloud1} className="cloud1" />

            <div className="wrapper-box-testimonial">
                <div className="img-title">
                    <img src={JoeImg} className="joe-img" />
                    <h2>Joe Turner</h2>
                    <p>Core Bankless DAO team member since the beginning</p>
                </div>
                <div className="box-text">
                    <img src={TestimonialBox} className="testimonial-box-img" />
                    <img src={TestimonialBoxTablet} className="testimonial-box-img-tablet" />
                    <img src={TestimonialBoxMobile} className="testimonial-box-img-mobile" />
                    <div className="text-inside">
                        <h2>
                            “In the three months since ThriveCoin began powering Bankless DAO, we’ve seen:
                        </h2>
                      
                        <ul>
                            <li>87% of all engaged users rewarded </li>
                            <li> 65% seasonal increase in contributions </li>
                            <li> 50% increase in top earner rewards</li>
                        </ul>



                       
                        <h2>
                            ThriveCoin is issuing in the future of web3 one contribution at a time.”
                        </h2>
                    </div>
                </div>
            </div>
            <img src={Cloud2} className="cloud2" />

        </div>
    );
}

export default Testimonial;