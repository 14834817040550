import React from "react";
import Header from "../Header";
import Logo from "../../assets/img/logo_2.svg";
import WolfImg from "../../assets/img/about-us/wolf.svg";
import BgStars from "../../assets/img/about-us/bg-stars.svg";
import BarBottom from "../../assets/img/about-us/bar-bottom.svg";
import BarBottomMobile from "../../assets/img/about-us/bar-bottom-mobile.svg";

const BannerAboutUs = () => {

    return (
        <div className="container items-column banner-wrap">
            <Header classHeader="header-green" logoSrc={Logo}/>
            <div className="bg-stars-wrapper">
                <img src={BgStars} alt="BgStars"/>
                </div>
            <div className="banner-about-us">
                <div className="banner-about-us-img">
                
                    <img src={WolfImg} alt="WolfImg"/>
                </div>
            <div className="banner-about-us-text">
                <h1 className="header-text">
                About Us
                </h1>
                <p className="description">
                ThriveCoin supports crypto communities that want to thank their members who make valuable and meaningful contributions. 
                    </p>
            </div>
        
            </div>

            <div className="bg-bottom-bar">
            <img src={BarBottom} alt="BarBottom" className="bar-bottom-about-us"/>
            <img src={BarBottomMobile} alt="BarBottom" className="bar-bottom-mobile-about-us"/>
            </div>
        </div>
    );
}

export default BannerAboutUs;