const SCRIPTURL = "https://script.google.com/macros/s/AKfycbznbfGeuCP3pr8exMRdTvYgcdfVhlYEBE0tEgdWsYSi_A49znU9lN8qtJo8xV2ZUZU/exec";

const submitToGoogleSheets = (event) => {
  event.preventDefault();
  const form = event.currentTarget;
  const body = new FormData(form);

  return fetch(SCRIPTURL, { method: "POST", body });
};

export default submitToGoogleSheets;
