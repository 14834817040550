import React from "react";
import Clouds from "../assets/img/home/clouds.svg";
import CarInRoad from "../assets/img/home/car-in-road.svg";
import RoadDots from "../assets/img/home/road-dots.svg";
import RoadDotsTablet from "../assets/img/home/road-dots-tablet.svg";
import RoadDotsMobile from "../assets/img/home/road-dots-mobile.svg";

const OurRoadMap = () => {

    return (
        <>
        <div className="our-road-map-container">

            <img src={Clouds} alt="Clouds" className="clouds-img" />


            <div className="car-text">
                <img src={CarInRoad} alt="v" className="car-in-road-img" />
                <div className="road-text">
                    <h2>Our Roadmap</h2>
                    <p>The ThriveCoin team is constantly pushing out new innovations in support of top web3 organizations.
                    </p>
                </div>
            </div>
           
        <div className="road-lines">
            <div className="road-image-wrapper">
          
            <img src={RoadDots} alt="line" className="road-line-dots"/>
            <img src={RoadDotsTablet} alt="line" className="road-line-dots-tablet"/>
            <img src={RoadDotsMobile} alt="line" className="road-line-dots-mobile"/>

            <div className="left-text">
                <span>Early 2022</span>
                <span>Mid 2022</span>
                <span>Late 2022</span>
                <span>Early 2023</span>
                <span>Mid 2023</span>
                <span>End 2023</span>
            </div>

            <div className="right-text">
                <span>V1 ThriveProtocol: on-chain record of contributions and automatic THRIVE token distribution</span>
                <span>Auto-validate any API accessible contribution activity, filtering out bad actors and lazy actors </span>
                <span>Raffle rewards system and native token support for all  partner communities. </span>
                <span>Can support 50% of all web3 contribution types across dozens of platforms</span>
                <span>V2 Thrive Protocol release: more on-chain contributions, flexible reward structures, on-chain contribution certifiers</span>
                <span>Open API access to the Thrive Ecosystem</span>
                <span>Support >90% of all web3 contribution </span>
            </div>
        </div></div>
        </div>
          <div className="shaped-bg">
          </div>
          <div className="bg-grayed"></div>
          </>
    );
}

export default OurRoadMap;