import React from "react";
import Footer from "../Footer";
import BannerAboutUs from "./BannerAboutUs";
import Testimonials from './Testimonials';
import JoinOurCommunities from './../JoinOurCommunities';

const AboutUs = () => {

    return (
      <div>
        <div className="gr-navy banner-about-us-wrapper">
          <BannerAboutUs/>
          <div className="next-section center"></div>
        </div>

       
        <div className="gr-navy testimonials-wrapper">
          <Testimonials />
        </div>

        <div className="gr-navy join-our-communities-wrapper">
        <JoinOurCommunities />
      </div>

        <div className="fp-auto-height footer-wrapper">
        <Footer/>
        </div>
      </div>
    );
};

export default AboutUs;
