import React from "react";
import Leaf from "../assets/img/home/leaf-white.svg";
import Header from "./Header";
import Monkeys from "../assets/img/home/monkeys.svg";
import ApecoinDao from "../assets/img/home/apecoin-dao.svg";
import BanklessDao from "../assets/img/home/bankless-dao.svg";
import ParticleDao from "../assets/img/home/particle-dao.svg";
import {THRIVECOIN_APP} from "../utils/Links";

const BannerHeader = (props) => {
  return (
    <div className="container banner-wrap">
      <Header />
      <div className="banner">
        <div className="banner-header-text">
          <h1 className="header-text">
            <span> ThriveCoin: scalable, equitable rewards for web3 communities </span>
          </h1>

          <p class="description">
          We make it easier and faster for web3 communities to reward contributors - and it’s all on-chain.
         
           </p>
          <a href={THRIVECOIN_APP(7)} target="_blank" rel="noreferrer">
                <button  className="btn btn--outline btn--outline--blackish">
                  Get Started
                </button>
            </a>

            <div className="partners">
              <h2 className="partners-desc">We support top web3 communities like: </h2>
              <div className="partners-wrappers">
              <div className="partner-box">
                <img src={ApecoinDao} alt="apecoin"/>
                <p className="partners-title">ApeCoin</p>
                <p className="partners-title">DAO</p>
              </div>

              <div className="partner-box">
                <img src={BanklessDao} alt="banklessdao"/>
                <p className="partners-title">Bankless</p>
                <p className="partners-title">DAO</p>
              </div>

              <div className="partner-box">
                <img src={ParticleDao} alt="particledao"/>
                <p className="partners-title">Particle</p>
                <p className="partners-title">DAO</p>
              </div>

              </div>
            </div>
        </div>
        <img className="leaf" src={Leaf} alt="Leaf"/>
        <div className="banner-header-image">
        
          <img className="monkeys-img" src={Monkeys} alt="Monkeys" />
        </div>
      </div>
    </div>
  );
};

export default BannerHeader;
