import React, { useRef, useState } from "react";
import ManWorkResponsive from "../assets/img/responsive/signup_img_responsive.svg";
import { Select, Input } from "@thrivecoin/ui-components";
import { useOutletContext } from "react-router-dom";
import { prospectiveOptions } from "../utils/FormOptions";

export const useRole = (_role, autoOpen, onSubmit) => {
  const [role, setRole] = useState(_role);
  const showOther = role === "Other";

  const onRoleChange = (event) => {
    setRole(event.target.value)
    autoOpen && onSubmit && onSubmit()
  };

  return { onRoleChange, showOther };
};

const SignUpForm = ({ description, className, autoOpen = true }) => {
  const toggleModal = useOutletContext();
  const ref = useRef();
  const _className = "signup-content " + className;
  const onSubmit = (event) => {
    event && event.preventDefault();
    const form = ref.current;
    const data = Object.fromEntries(new FormData(form));
    if(data.role === "Other" && !data.role2){
      return
    }
    toggleModal(data);
  };
  const { onRoleChange, showOther } = useRole(null, autoOpen, onSubmit);

  return (
    <form className={_className} onSubmit={onSubmit} ref={ref}>
      <div className="signup-content-description mtn-60 l-height">
        <h2 className="mb20 white">Join Us.</h2>
        <h5 className="mb50 primary">{description}</h5>

        <img className="img-r-singup" src={ManWorkResponsive} alt="Man Work" />

        <div className="signup-responsive">
          <h4 className="white">Sign up as a prospective...</h4>
          <div className="signup-content-select mb30">
            <Select name="role" onChange={onRoleChange}>
              {prospectiveOptions.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </Select>
          </div>

          {showOther && (
            <div className="signup-content-select mb30">
              <Input name="role2" placeholder="Please specify" required />
            </div>
          )}

          <button className="btn btn--secondary mb80" type="submit">
            Sign Up
          </button>
        </div>
      </div>
    </form>
  );
};

export default SignUpForm;
