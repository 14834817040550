import React, { useContext } from "react";
import { ToastList } from "@thrivecoin/ui-components";
import ToastContext from "./ToastContext";

const POSITION = "bottom-center";

const ToastService = () => {
  const { list, removeByID } = useContext(ToastContext);
  return <ToastList key={list.length} list={list} removeByID={removeByID} position={POSITION}/>;
};

export default ToastService;
