import React, {useState} from "react";
import Logo from "../assets/img/logo.svg";
import {Link, useLocation} from "react-router-dom";
import {THRIVECOIN_APP} from "../utils/Links";

const Header = ({classHeader, logoSrc}) => {
  const [isActive, setActive] = useState(false);
  const logo = logoSrc ? logoSrc : Logo;

  const toggleClass = () => {
    setActive(!isActive);
    document.querySelector('html').classList.toggle('disable-scroll');
  };

  const location = useLocation().pathname;

  return (
    <header className={`header ${classHeader}`}>
      <div className="header-logo">
        <Link to="/">
          <img alt="logo" src={logo}></img>
        </Link>
      </div>
{/*
      <nav className={isActive ? "nav-menu active" : " nav-menu"} onClick={toggleClass}>
        <ul>
          <li className={location === '/' && "active"}>
            <Link to="/">Home</Link>
          </li>
          <li className={location === '/aboutus' && "active"}>
            <Link to="/aboutus">About Us</Link>
          </li>
          <li className={location === 'https://www.missionariesnft.com/' && "active"}>
            <a href="https://www.missionariesnft.com/" target="_blank">The Missionaries</a>
          </li>

          <li className={location === '/articles' && "active"} >
            <Link to="/articles">Articles</Link>
          </li>
        </ul>
      </nav>*/}

      <div className="header-buttons">
        {/*<a href={THRIVECOIN_APP(5)} target="_blank" rel="noreferrer">
          <button className="btn btn--outline mr20">Sign In</button>
        </a>*/}
        <a href={THRIVECOIN_APP(7)} target="_blank" rel="noreferrer">
          <button className="btn btn--secondary">
            Get Started
          </button>
        </a>
{/*
        <button className={isActive ? "main-menu-indicator active" : " main-menu-indicator"} onClick={toggleClass}>
          <span className="line"></span>
        </button>*/}
      </div>

    </header>
  );
};

export default Header;
