import React from "react";
import RohanImg from "../../assets/img/what-we-create/testimonials/rohan.svg";
import RilindImg from "../../assets/img/what-we-create/testimonials/rilind.svg";
import ChristyImg from "../../assets/img/what-we-create/testimonials/christy.svg";
import ViganImg from "../../assets/img/what-we-create/testimonials/vigan.svg";
import DanielImg from "../../assets/img/what-we-create/testimonials/daniel.svg";
import RobertImg from "../../assets/img/what-we-create/testimonials/robert.svg";
import StarImg from "../../assets/img/what-we-create/testimonials/star.svg";
import StarImgTablet from "../../assets/img/what-we-create/testimonials/star-mobile.svg";

const items = [
    {
        name: "Rohan Handa",
        job: "Founding Member, ThriveCoin",
        formerjob: "Head of BD for Horizen Labs and ApeCoin (2nd largest DAO)",
        avatar: RohanImg,
        words: "“Every now and then a new vision erupts in web3 that can thoroughly and positively impact the landscape of the DAO ecosystem. ThriveCoin is one of them.”"
    },
    {
        name: "Rillind Mehmeti",
        job: "CTO and Founding Member, ThriveCoin",
        formerjob: "Former CTO top Stablecoins",
        avatar: RilindImg,
        words: "“At its best, the blockchain supports people and communities in reimagining themselves for the better. That is what ThriveCoin does.” "
    },
    {
        name: "Christy Lin",
        job: "People Strategy and Founding Member, ThriveCoin",
        formerjob: "Former Exec for CoinMarketCap and Binance",
        avatar: ChristyImg,
        words: "“I believe the most sacred work humans can be doing is helping to ensure that every person is seen, honored, rewarded for their contributions to the community. That is our work.” "
    },
    {
        name: "Vigan Abdurrahmani ",
        job: "Head of BlockChain and Founding Member, ThriveCoin",
        formerjob: "Former exec at top 7 global exchange",
        avatar: ViganImg,
        words: "“ThriveCoin is driven by a desire to make a profound and fundamental difference in the way protocols and the blockchain support communities in operating.” "
    },
    {
        name: "Daniel Jacobs",
        job: "Chief Vision Officer and Founding Member, ThriveCoin",
        formerjob: "Built and scaled two “Contribution Economies“",
        avatar: DanielImg,
        words: "“Opportunities to build something that truly, fundamentally improve humanity are rare. The blockchain offered us the tools, and ThriveCoin offers us the path. Now we get to see how far we can take it!” "
    },
    {
        name: "Robert Rome",
        job: "Chief Product Officer and Founding Member, ThriveCoin",
        formerjob: "Former product lead at UN, Obama White House, Skoll Foundation",
        avatar: RobertImg,
        words: "“I’ve worked on many big projects with big visions worth executing against. This one was special; we are on the cutting edge of something people have thought about forever but never been able to effectively implement... until now.” "
    }
]
const Testimonials = () => {
    return (
        <div className="container items-column h-100 testimonials-section">
            <div className="testimonials">
                <h1 className="header-text green">Words</h1>
                <p className="description">From some of our Founding Members...</p>

                <div className="testimonials-cards">
                    {items.map((item) => (
                        <div className="testimonial-wrapper-card">
                        <div className="testimonial-shadow"></div>
                        <div className="testimonials-card">
                            <div className="testimonials-card-top">
                                <div className="image-wrapper">
                                    <img src={StarImg} className="star-bg"/>
                                    <img src={StarImgTablet} className="star-bg-tablet"/>
                                    <img src={item.avatar} className="avatar-image" />
                                </div>

                             
                                <p className="description">
                                    {item.words}
                                </p>
                            </div>
                            <div className="testimonials-card-bottom">
                               
                                <h5 className="name">{item.name}</h5>
                                <div className="wrapper-job">
                                <p className="job">{item.job}</p>
                                <p className="former-job">{item.formerjob}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default Testimonials;