import React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Banner from "./Banner";
import Communities from "./Communities";
import Signup from "./Signup";
import Footer from "../Footer";

class MySection extends React.Component {
  render() {
    return <div className={'section ' + this.props.className} >{this.props.content}</div>;
  }
}

const anchors = [
  "BannerGetStarted",
  "Communities",
  "Singup"
];

const GetStarted = () => {
  return (
    <ReactFullpage
      autoScrolling={false}
      anchors={anchors}
      css3={true}
      normalScrollElements = '.ReactModal__Body--open, #footer'
      responsiveWidth= {1199}
      navigation
      navigationTooltips={anchors}
      sectionsColor={[
        "#35476A",
        "",
        "#FFFFFF"
      ]}
      onLeave={(origin, destination, direction) => {
        //console.log("onLeave event", { origin, destination, direction });
      }}
      render={({ state, fullpageApi }) => {

        return (
          <div>
            <MySection content={<Banner fullpageApi={fullpageApi}/>}/>
            {/*<MySection className="gr-white-blue" content={<Communities/>} />*/}
            <MySection className="gr-green" content={<Signup/>} />
            <MySection className="fp-auto-height" content={<Footer/>} />
          </div>
        );
      }}
    />
  );
};

export default GetStarted;
