import React, { useState } from "react";

let counter = 0;
export const useToastContext = () => {
  let [list, setList] = useState([]);

  const showToast = ({ content, dismissTime = 4000, position = "bottm-right", type = "info" }) => {
    const data = { content, position, type, dismissTime, id: counter++ };
    setList([...list, data]);
  };

  const successToast = (params) => {
    showToast({ ...params, type: "success" });
  };

  const dangerToast = (params) => {
    showToast({ ...params, type: "danger" });
  };

  const infoToast = (params) => {
    showToast({ ...params, type: "info" });
  };

  const warningToast = (params) => {
    showToast({ ...params, type: "warning" });
  };

  const removeByID = (id) => {
    list = list.filter((item) => item.id !== id);
    setList([...list]);
  };

  const hideLast = () => {
    list.pop();
  };

  return {
    successToast,
    dangerToast,
    infoToast,
    warningToast,
    removeByID,
    hideLast,
    list,
  };
};

const ToastContext = React.createContext({});

export default ToastContext;
