import React from "react";
import OurCommunityImg from "../assets/img/home/our-community.png";

const OurCommunity = () => {

    return (
        <div className="our-community-container">
            <div className="box">
                <h1 className="header-text ">
                    Our Community
                </h1>

                <p className="description">
                    We are entrepreneurs, engineers, designers, students, storytellers, degens, regens, investors, artists, and friends.
                    <br /><br />
                    We are joined by a common bond: a desire to build the Contribution Economy:
                    <br /><br />
                    A world in which it doesn’t matter who you are, where you are, or what your background is… your community contributions are rewarded proportionate to the value you create!
                </p>
            </div>

            <img src={OurCommunityImg} className="our-community-img" />
         
        </div>
    );
}

export default OurCommunity;