import React from "react";
import ThcMiss from "../assets/img/home/thc-miss.svg";

const TheMissinoaries = () => {

    return (
        <>
            <div className="thc-missionaries-container">
                <div className="box">
                    <h1 className="header-text ">
                        The Missionaries of Thrive
                    </h1>

                    <p className="description">
                        ThriveCoin aims to help all our partner communities build self-sustaining, thriving contribution ecosystems.
                        <br /><br />
                        As a special incentive, top contributors in our partner communities will soon be able to acquire a Missionaries NFT, which has unique and significant utility.
                        <br /><br />
                        We’ll share more about our Missionaries NFTs soon - and how they can inspire even more incredible contributions to our partner communities.
                        <br /><br />
                        Stay tuned!


                    </p>
                </div>

                <img src={ThcMiss} className="thc-missionaries-img" />

            </div>
            <div className="bottom-line"></div>
        </>
    );
}

export default TheMissinoaries;