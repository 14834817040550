import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ModalSignUp from "./components/ModalSignUp";
import ToastContext, { useToastContext } from "./components/ToastService/ToastContext";
import ToastService from "./components/ToastService/ToastService";

function Layout() {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setInitialValues] = useState({});
  const toggleModal = (values) => {
    setIsOpen(!isOpen);
    if(isOpen){
      setInitialValues({});
    }
    else{
      setInitialValues(values)
    }
  };

  const toastValues = useToastContext();

  return (
    <ToastContext.Provider value={toastValues}>
      <ToastService />
      {isOpen && <ModalSignUp isOpen={isOpen} close={toggleModal} {...values} />}
      <Outlet context={toggleModal} />
    </ToastContext.Provider>
  );
}

export default Layout;
